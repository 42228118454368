// category list
.catalogsearch-result-index .page-title {
  margin-top: 30px;
}

.pager .limiter select {
  width: 44px
}

.catalogsearch-result-index, .catalog-category-view, .catalog-product-view {
  .breadcrumb-container{
    display: block;
  }
  .btn-primary {
    background: $c-to-cart;
    font-size: 16px;
    border: none;
    border-radius: 0;
    position: relative;
    padding: 10px 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:before {
      font-family: 'iconfont';
      content: '\e6ca';
      display: block;
      margin-right: 10px;
      font-size: 24px;
    }

    &:hover {
      color: $c-white
    }
  }
}

@media(max-width: $screen__m){
  .category-title{
    display: block;
    position: absolute;
    top: -50px;
  }
  .catalog-category-view{
    .col-main{
      position: relative;
    }

  }
  .recomendBlock .swiper-container .product-item{
    padding: 20px 30px
  }
}
@media(max-width: $screen__s){
  .recomendBlock .swiper-container .product-item{
    padding: 10px
  }
}
.catalog-category-view, .catalogsearch-result-index {
  .products-list {
    background: none;
  }

  label {
    font-weight: normal;
  }

  .minimal-price-link {
    .price {
      color: $color-black;
      font-size: 20px;
    }

    .label {
      background: none;
      color: $color-black;
      font-weight: normal;
      font-size: 14px;
      font-style: italic;
      padding: 0 5px 0 0;

    }
  }

  .toolbar-top {
    .pages {
      display: none;
    }
  }

  h2.product-name {
    height: auto;

    a {
      text-align: left;
    }
  }

  .toolbar-bottom .toolbar {
    border: none;
  }

  .pager {
    .pages {
      float: none;
      margin: 0;

      strong {
        display: none;
      }

      ol {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
      }

      li {
        margin: 0 10px;
      }
    }

    li {
      & > a, & > span {
        border: 1px solid $c-grey;
        width: 28px;
        height: 28px;
        line-height: 28px;
        text-align: center;
        padding: 0;
        border-radius: 0 !important;
      }

      & > a {
        &.previous,
        &.next {
          border: none;

          img {
            display: none;
          }

          &:before {
            font-family: 'iconfont';
            content: '\e600';
            display: block;
            color: $color-black;
            font-size: 22px;
          }

          &:hover,
          &:visited,
          &:focus,
          &:active {
            background: none;
          }
        }

        &.next:before {
          transform: rotate(180deg);
          -webkit-transform: rotate(180deg);
          -moz-transform: rotate(180deg);
          -ms-transform: rotate(180deg);

        }
      }
    }
  }


  select {
    -webkit-appearance: none;
    appearance: none;
    -moz-appearance: none;
    padding-right: 20px;
    background: url("../images/downArrow.png") no-repeat 97% center/ 10px;
    margin-right: 10px;
    text-transform: capitalize;

  }
  select::-ms-expand {
    display: none;
  }

  .col-main {
    width: 768px;

  }


  .breadcrumbs {
    float: none;
    display: none;
  }

  .filters-group {
    width: 100%;

    ol {
      overflow-y: auto;
    }

    td {
      border: none
    }

    .filters-options {
      display: none;
    }

    .filter-item {
      &.closeUp {
        .filter_title:after {
          content: '\e605'
        }

        .filters-options {
          display: block;
          border: 1px solid $c-grey;
          padding: 10px;
          max-height: 200px;
          overflow-y: scroll;
        }
      }
    }

    .filter_title {
      font-size: 16px;
      display: block;
      position: relative;
      cursor: pointer;
      margin: 5px 0;

      &:after {
        font-family: 'iconfont';
        content: '\e603';
        font-size: 14px;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate(0, -50%);
        -webkit-transform: translate(0, -50%);
        -moz-transform: translate(0, -50%);
        -ms-transform: translate(0, -50%);
      }
    }
  }

  .sidebar {
    width: 362px;

    .block {
      border: none;

      .block-title {
        background: none;
        border-bottom: none;
        padding: 7px 0px;

        strong span {
          padding-left: 0;
          font-size: 16px;
        }
      }
    }

    .currently {
      ol {
        background: $c-grey;
        padding: 10px 20px;

        li {
          &, .label-info {
            background: none;
            font-size: 16px;
            color: $color-black;
            font-weight: normal;
            padding-left: 0;
          }

          a {
            float: right;
            font-size: 14px;
          }

        }
      }

      .actions {
        margin-bottom: 30px;
        text-align: right;
        background: $c-grey;
        padding: 0 20px 10px 0;
        display: block;
        float: none;

        a {
          color: $color-black;
          font-weight: normal;
          text-align: right;
          background: none;
          font-size: 16px;
          text-decoration: underline;
          display: block;
          float: none;
        }
      }
    }

    .block-subtitle {
      font-size: 18px;
      font-weight: bold;
      color: $color-black
    }

    .block-layered-nav {
      background: none;

      dt {
        padding-left: 0;
        font-weight: 500;
        cursor: pointer;

        &:after {
          font-family: 'iconfont';
          content: '\e603';
          font-size: 14px;
          float: right;
        }

        &.closeUp:after {
          content: '\e605'
        }
      }

      dd {
        display: none;
        padding: 10px 0;
        border: 1px solid $c-grey;
        padding: 10px;
        max-height: 200px;
        overflow-y: scroll;

      }

      .block-content {
        padding: 0;

        li, a {
          font-size: 14px;
          color: $color-black;
        }

      }

    }

  }

  @media (max-width: $screen__sl) {
    .col-main {
      width: 65%
    }
    .sidebar {
      width: 30%
    }

  }

  .toolbar-top {
    margin: 40px 0;
    @media (max-width: $screen__m) {
      margin: 20px 0;
    }
  }


  .toolbar {
    background: none;
    border-bottom: 1px solid $c-grey;

    .pager {
      margin: 10px 0
    }

    .sort-by {
      float: right;
      margin-right: 20px;
      position: relative;

      //&:after {
      //  font-family: 'iconfont';
      //  content: '\e68c';
      //  font-size: 12px;
      //  float: right;
      //  position: absolute;
      //  right: 20px;
      //  top: 3px;
      //}
    }

    .limiter {
      position: relative;

      //&:after {
      //  font-family: 'iconfont';
      //  content: '\e68c';
      //  font-size: 12px;
      //  float: right;
      //  position: absolute;
      //  right: 66px;
      //  top: 3px;
      //}

      select {
        padding-right: 15px;
      }
    }
  }

  .category-products {
    border: none;
  }

  .products-list {
    li.item {
      padding: 30px;
      background: $c-grey;
      margin-bottom: 30px;
      border-bottom: none;
      display: flex;
      justify-content: space-between;
      @media (max-width: $screen__xm) {
        padding: 30px 20px;
      }
    }

    .product-image {
      width: 200px;
      height: auto;
      margin: 0 30px 0 0;
      @media (max-width: $screen__xm) {
        width: 140px;
        margin-right: 20px;
      }

      img {
        width: 100%;
        height: auto;
      }
    }

    .product-name a {
      font-size: 16px;
      font-weight: normal;
    }

    .desc .link-more {
      color: $color-01;
      font-size: 14px;
    }

    .product-shop {
      margin-left: 0;
      width: 67%;

      .product-right {
        float: none;
        padding-top: 0;
        text-align: left;
        width: auto;

        .price-box {
          margin: 20px 0;
          min-height: 60px;
          .minimal-price-link{
            position: static;
            background: none;
            height: auto;
            width: auto;
            justify-content: start;
            &:visited, &:hover, &:active, &:focus{
              border: none;
            }

            @media (max-width: $screen__m) {
              justify-content: center;
            }
          }
        }
      }

      .product-left {
        float: none;
        width: auto;
      }

    }

    .add-to-links {
      margin-top: 30px;

      li {
        margin-right: 30px;
        @media (max-width: $screen__xm) {
          margin-right: 10px;
        }
      }


      .separator {
        display: none;
      }

      a {
        color: $c-to-link;
        font-size: 16px;
        position: relative;
        padding-left: 30px;
        @media (max-width: $screen__xm) {
          font-size: 14px;
        }

        &:before {
          position: absolute;
          font-size: 18px;
          display: block;
          left: 0;
          top: 0;
          line-height: 18px;
        }

        &.link-wishlist:before {
          font-family: 'iconfont';
          content: '\e601';
        }

        &.link-compare {
          &:before {
            font-family: 'iconfont';
            content: '\e606';
            font-size: 18px;
            width: 20px;
            text-align: center;
          }
        }
      }
    }
  }

  @media (max-width: $screen__m) {
    .pager .amount {
      display: none;
    }
    .toolbar {
      .limiter {
        display: none;
      }
    }
    .sort-by {
      label {
        font-size: 18px;
        margin-right: 10px
      }
    }
    select {
      border: none;
      font-size: 18px;
    }
    .popup {
      display: block !important;
      position: fixed;
      width: 100%;
      top: 0;
      left: 0;
      background: $c-white;
      height: 100%;
      z-index: 111;
      overflow-y: scroll;
    }
    .col-main, .sidebar {
      float: none;
      width: auto;
    }
    .listActions {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      &.showFull{
        li{
          width: 100%;
        }
      }
      li {
        width: 49%;
        background: $c-font;
        font-size: 16px;
        text-align: center;
        color: $c-white;
        padding: 20px 0;

        button {
          background: none;
          padding: 20px 0;

          span, span span {
            background: none;
            border: none;
            text-transform: capitalize;
            width: 100%;
            padding: 0;
            font-size: 16px;
            height: auto;
            color: $c-white
          }
        }

        &:last-child {
          padding: 0;

        }
      }
    }
    .leftFilter {

      .block-subtitle, .options-subtitle {
        font-size: 26px;
        text-align: center;
        position: relative;
        padding: 20px 30px;
        border-bottom: 1px solid $c-grey;
        font-weight: normal;

        span {
          float: left;
          font-size: 30px;
          display: block;
        }
      }

      .filters-group {
        margin-top: 20px;

        dd.filter-item {
          padding: 20px 30px;
          margin: 0 30px 20px 30px;
          background: $c-grey;

          .filter_title {
            font-size: 24px;
            position: relative;

            &:after {
              content: '\e68c';
              display: block;
              transform: translateY(-50%) rotate(-90deg);
              -webkit-transform: translateY(-50%) rotate(-90deg);
              -moz-transform: translateY(-50%) rotate(-90deg);
              -ms-transform: translateY(-50%) rotate(-90deg);
              position: absolute;
              right: 0;
            }
          }

          .filters-options {
            display: none;
            padding: 0;

            &.popup {
              height: 100%;
              max-height: unset;
            }

            li {
              border-bottom: 1px solid $c-grey;
              padding: 20px;
              margin: 0 30px;
              font-size: 24px;
            }
          }
        }
      }

      .currently {
        ol {
          margin: 20px 30px 0;
          padding: 30px;

          li {
            &, .label-info {
              font-size: 24px;
            }
          }

          a:after {
            font-size: 20px;
          }
        }

        li {
          margin: 0 0 20px;

        }

        .actions {
          margin: 0 30px;
          text-align: center;

          a {
            background: $c-font;
            width: 180px;
            padding: 15px 0;
            color: $c-white;
            display: inline-block;
            border-radius: 0;
            margin: 20px 0;
            text-decoration: none;
            text-align: center;
          }
        }
      }
    }

    .desc {
      display: none;
    }
    .products-list {
      justify-content: space-between;
      display: flex;
      flex-wrap: wrap;

      .product-image {
        float: none;
      }

      button {
        width: 100%;
        padding: 10px 0;
      }

      .product-shop {
        width: auto;
        margin-top: 20px;

        .product-right {
          .price-box {
            text-align: center;
          }

          p {
            text-align: center;
          }
        }

      }

      li.item {
        width: 48%;
        display: block;
        text-align: center;
      }

      .add-to-links {
        display: flex;
        justify-content: flex-end;

        a {
          font-size: 0;
          display: block;
          padding-left: 10px;

          &:before {
            display: inline-block;
            position: static;
          }
        }
      }
    }
  }

  @media (max-width: $screen__sm) {
    .listActions li {
      padding: 13px 0;
      font-size: 14px;

      button {
        padding: 13px 0;

        span, span span {
          font-size: 14px;
        }
      }

      &:last-child {
        padding: 0;

      }
    }


    .leftFilter {
      .currently {
        ol {
          padding: 20px;
          margin: 20px 20px 0;

          li {
            margin-bottom: 10px;

            &, .label-info {
              font-size: 16px;
            }
          }
        }

        .actions {
          margin: 0 20px;

          a {
            margin: 10px 0;
            font-size: 14px;
          }
        }
      }

      .filters-group dd.filter-item {
        padding: 15px 20px;
        margin: 0 20px 20px 20px;

        .filter_title {
          font-size: 16px;
        }

        .filters-options li {
          font-size: 18px;
          margin: 0 20px
        }
      }
    }

  }

  @media (max-width: $screen__s) {
    &, .catalog-product-view {
      .btn-primary {
        font-size: 14px;

        &:before {
          font-size: 20px;
        }
      }
    }
    .products-list li.item {
      padding: 30px 10px
    }
  }

  .leftFilter {
    .currently {
      ol {
        li {
          &, .label-info {
            font-size: 14px;
          }
        }
      }

    }


    .filters-group dd.filter-item {
      .filters-options li {
        font-size: 16px;
        padding: 2px 0;
        @media(max-width: $screen__m){
          line-height: 40px
        }

        a{
          display: block;
        }
      }

      .filter_title {
        font-size: 16px;
      }
    }
  }
}

// product view
.col1-layout .product-view .product-shop {
  width: 626px
}

:root {
  --swiper-theme-color: none
}
.recomendBlock{
  .block-title{
    font-size: 16px;
    margin: 20px 0;
    border-bottom: 1px solid #ededed;
    padding-bottom: 10px;
  }
  .price-box{
    text-align: center;
    .price{
      color: #000;
    }
  }

  .swiper-container{
    padding-bottom: 60px;
    .product-item{
      padding: 20px 40px;
      img{
        width: 100%;
        height: auto;
      }
    }
  }

  .swiper-container-horizontal > .swiper-pagination-bullets {
    text-align: center;
    .swiper-pagination-bullet{
      border: 1px solid #000;
      margin: 10px;
      width: 10px;
      height: 10px;
      background: #fff;
      opacity: 1;
      &.swiper-pagination-bullet-active{
        background: black;
      }
    }
  }
}
.product-view {
  background: none;
  border: none;
  padding: 0;

  .product-img-box {
    width: 492px;

    .mz-figure > img {
      max-width: unset !important;
      max-height: unset !important;
    }

    .more-views {
      position: relative;
      margin: 20px 0;

      ul {
        margin: 0;
      }

      li {
        float: none;
        text-align: center;
        margin: 0;

        a.mz-thumb {
          border: 1px solid $c-grey;

          &.mz-thumb-selected {
            border-color: $c-font
          }
        }
      }

      .swiper-container {
        margin: 0 50px;
      }

      .swiper-btn {

        background: none;
        width: 40px;
        height: 80px;
        text-align: center;
        border: 1px solid $c-grey;
        box-shadow: none;

        &:after {
          content: '\e600';
          display: block;
          font-family: 'iconfont';
          font-size: 18px;
          color: $c-desc-title;
        }

        &:hover, &:focus, &:active, &:visited {
          border-color: $c-font;
          box-shadow: none;
        }

        &.swiper-button-next {
          right: 0;
          top: 0;
          margin-top: 0;

          &:after {
            transform: rotate(180deg);
            -webkit-transform: rotate(180deg);
            -moz-transform: rotate(180deg);
            -ms-transform: rotate(180deg);

          }
        }

        &.swiper-button-prev {
          left: 0;
          top: 0;
          margin-top: 0;
        }
      }
    }

  }

  a[data-zoom-id], .mz-thumb {
    &:hover {
      border: none;
      padding-bottom: 0;
    }

    img {
      border: none;
      padding-bottom: 0;
    }
  }

  .mz-thumb {
    &:hover {
      border: none;
      padding-bottom: 0;
    }

    &.mz-thumb-selected img {
      border: none
    }
  }

  .product-name h1 {

    font-size: 18px;
    line-height: 20px;
    font-weight: normal;
    margin: 0
  }

  .about-stock {
    font-size: 16px;
    margin: 15px 0;
    display: block;
  }

  .short-description {
    display: flex;
    padding-top: 20px;

    h2 {
      margin-top: 0;
      color: $c-desc-title;
      width: 140px
    }

    .std {

    }
  }

  .sold-attention {
    color: $c-price;
    margin-top: 20px;
  }

  .product-options-bottom {
    border: none;
    padding: 0;

    .btn-primary {
      margin: 30px 0 0 0;

    }
  }

  .product-options {
    border: none;
    background: none;
    padding: 0;

    em {
      display: none;
    }

    .options-list {
      padding-left: 0;
      display: flex;
      flex-wrap: wrap;

      a {
        width: auto;
        margin-left: 0;
        margin-right: 10px;
        padding: 5px 20px;

        &:active, &.active, &:hover {
          border-color: $c-to-cart;
          color: $c-to-cart;
        }
      }

    }
  }

  .product-shop {
    .price-box {
      margin: 20px 0;
    }

    .availability {
      display: block;
      font-size: 16px;
      color: $c-desc-title;
      margin-top: 20px;
      text-transform: capitalize;
    }

    .tier-prices li {
      &, .price {
        font-size: 16px;
      }

      .benefit {
        color: $c-price;
      }
    }
  }

  .product-collateral .box-collateral {
    margin: 0 40px;
    colgroup col{
      width: 50%;
    }
    table{
      th, td{
        white-space: pre-wrap;
      }
      td{
        padding: 10px 0
      }
    }
  }

  .tabs {
    border-bottom: 1px solid $c-grey;

    li {
      height: auto;
      border-left: 1px solid $c-tocart;
      border-right: none;
      width: 174px;

      &:last-child {
        border-right: none;
      }

      a {
        background: $c-grey;
        font-size: 18px;
        font-weight: normal;
        padding: 10px;
        text-transform: capitalize;

      }

      &.active a {
        background: $c-to-cart;
      }
    }
  }

  .data-table {
    td, th {
      &.label {
        font-size: 16px;
        border: none;
        background: none;
        font-weight: normal;
        padding: 10px;

      }
    }

    tr.even {
      background: none;
    }

    td {
      border: none;
      padding: 10px 0;
      font-size: 14px;
    }

    th {
      text-transform: capitalize;
    }
  }

  @media (max-width: $screen__sl) {
    .product-img-box {
      width: 42%
    }
  }

}
.block-related{
  margin-top: 30px;
  position: relative;
  .block-title{
    font-size: 20px;
    border-bottom: 1px solid $c-grey;
    text-transform: uppercase;
    padding: 10px 0;
  }
  .product{
    text-align: center;
  }
  .swiper-pagination{
    width: 100%;
    display: flex;
    justify-content: center;
    opacity: 1;
    .swiper-pagination-bullet{
      background: none;
      border: 1px solid $color-black;
      width: 10px;
      height: 10px;
      margin: 10px;
      &.swiper-pagination-bullet-active{
        background: $color-black;
      }
    }
  }
  .mini-products-list{
    margin-top: 40px;
    .product-image{
      float: none;
      width: auto;
      border: none;
      padding: 0;
      display: inline-block;
    }
    .product-details{
      margin-left: 0;
      position: relative;
      .price{
        font-size: 16px;
      }
      .product-name{
        margin-top: 20px;
        a{
          color: $color-black;
          text-decoration: none;
          font-size: 16px;
          line-height: 20px;
        }
      }
    }

  }

}

@media (max-width: $screen__sl) {
  .col1-layout .product-view .product-shop {
    width: 53%
  }
}

@media (max-width: $screen__m) {
  .col1-layout .product-view .product-shop,
  .product-view .product-img-box {
    width: auto;
    float: none;
  }
  .product-view .product-name h1{
    font-size: 16px;
  }
  .block-related .mini-products-list .product-image img{
    width: 100%;
    height: auto;
  }
}
@media (max-width: $screen__ss) {
  .product-view .product-options .options-list a{
    width: 200px;
    margin: 0 0 14px 0;
    text-align: left;
  }
}

.product-shop .regular-price .price {
  font-size: 20px;
}

.add-to-cart {
  label {
    float: none;
    display: block;
    font-size: 16px;
    margin: 0 0 10px;
  }

  .qty {
    text-align: center;
    float: none;
    height: 36px;
    line-height: 36px;
    width: 86px;
    margin-top: 0;
    border: none;

  }
}

.to-cart-container {
  display: flex;
  width: 112px;
  border: 1px solid $c-tocart;
  justify-content: space-between;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  justify-items: center;

  input {
    border: none;
    text-align: center;
  }

  span {
    background: $c-breadcrumb;
    display: block;
    width: 26px;
    text-align: center;
    height: 36px;
    line-height: 36px;
    font-size: 12px;
    cursor: pointer;

    &.plus {
      border-left: 1px solid $c-tocart
    }

    &.reduce {
      border-right: 1px solid $c-tocart;
    }
  }
}

.product-essential .add-to-box .add-to-cart {
  padding-top: 0;

  button {
    float: none;
    margin-top: 20px;
    background: $c-to-cart;
    font-size: 16px;
    border: none;
    border-radius: 0;
    position: relative;
    padding: 10px 20px 10px 50px;

    &:before {
      font-family: 'iconfont';
      content: '\e6ca';
      display: block;
      float: left;
      margin-right: 10px;
      font-size: 24px;
      position: absolute;
      left: 20px;
      top: 50%;
      transform: translate(0, -50%);
      -webkit-transform: translate(0, -50%);
      -moz-transform: translate(0, -50%);
      -ms-transform: translate(0, -50%);
    }
  }
}

@media (max-width: $screen__m) {
  .product-view {
    .product-img-box .more-views {
      .swiper-container {
        margin: 0;
      }

      li img {
        width: 100%;
        height: auto;
      }
    }

    .swiper-pagination {
      width: 100%;
      margin: 20px 0;
      position: static;

      .swiper-pagination-bullet {
        width: 14px;
        height: 14px;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        border: 1px solid $c-grey;
        opacity: 1;
        background: none;
        margin: 0 5px;

        &.swiper-pagination-bullet-active {
          background: $c-grey;
        }

        &:active, &:visited, &:focus {
          border: none;
        }
      }
    }
    .block-related{
      .swiper-pagination .swiper-pagination-bullet{
        border-color: $color-black;
        &.swiper-pagination-bullet-active{
          background: $color-black;
        }
      }
    }

    //.tabs{
    //  border-bottom: none;
    //  li{
    //    border-color: #bfbfbf;
    //    width: 32%;
    //    &.active a{
    //      background: $c-font;
    //    }
    //    &:first-child{
    //      border-left: none;
    //    }
    //  }
    //}

    .product-collateral {
      .box-collateral {
        margin: 20px 0;

      }


      .tabs_title {
        display: block;
        background: $c-grey;
        padding: 10px;
        font-size: 16px;
        border-bottom: 1px solid #dfdfdf;
        text-transform: capitalize;

        &:last-child {
          border-bottom: none;
        }

        &:after {
          font-family: 'iconfont';
          content: '\e603';
          float: right;
        }

        &.closeUp {
          background: $c-font;
          color: $c-white;
          margin-bottom: 0;

          &:after {
            content: '\e605'
          }
        }

      }
    }

  }
}

@media (max-width: $screen__sm) {
  .product-view {
    //.tabs li{
    //  a{
    //    font-size: 16px;
    //  }
    //}
    .data-table {
      td, th {
        &.label {
          font-size: 14px;
        }
      }
    }
  }

}

@media (max-width: $screen__s) {
  .product-view {
    .tabs li {
      width: auto;
    }

    .product-shop .tier-prices li {
      &, .price {
        font-size: 14px;
      }
    }
  }

}

//shopping cart
.checkout-cart-index .breadcrumb-container {
  display: none;
}

.cart {
  .product-name a{
    line-height: 20px;
  }
  .page-title {
    margin: 50px 0 30px 0;
    padding-bottom: 10px;

    h1 {
      font-size: 20px;
      font-weight: bold;
    }
  }

  .discount, .shipping {
    border: none;

    h2 {
      font-weight: bold;
      font-size: 14px;
      margin: 0 0 10px 0
    }
  }

  .discount {
    padding: 0;

    label {
      font-weight: normal;
      color: $c-desc-title;
      margin-bottom: 10px;
    }

    .discount-container {
      display: flex;
      margin-top: 10px;

      .input-box {
        width: 70%
      }

    }

    button {
      span {
        background: $c-coupon-btn;
        color: $c-white;
        border: none;
        font-size: 12px;
      }

      & > span {
        height: 41px;
        line-height: 41px;
      }
    }

    input {
      border: 1px solid $c-grey;
      height: 41px;
      width: 100%;
      padding: 0 5px;
    }
  }

  .cart-collaterals {
    margin: 30px 60px;

    .col2-set {
      width: 400px;

      .col-2 {
        width: auto
      }
    }

    @media (max-width: $screen__xm) {
      margin: 30px 0;
      .col2-set {
        width: 360px;
      }
    }
  }

  .note-container {
    margin-top: 40px;

    h2 {
      font-size: 14px;
      font-weight: bold;
      text-transform: capitalize;
      margin-bottom: 15px;
    }

    p {
      margin-bottom: 30px;

      &.second-note {
        color: $c-red
      }
    }

  }

  .totals {
    width: 360px;

    .ship-to-container {
      label {
        font-size: 14px;
        display: block;
        margin-bottom: 5px;
      }
    }

    .ship-to-group {
      margin-bottom: 20px;

      select {
        border: 1px solid $c-grey;
        padding: 0 5px;
        width: 100%;
        height: 36px;
        line-height: 36px;
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        -ms-appearance: none;
        text-transform: capitalize;
        background: url("../images/downArrow.png") no-repeat 97% center/ 15px;
      }
      select::-ms-expand {
        display: none;
      }
    }

    .ship-to-field {
      position: relative;
      color: $c-desc-title;
      //&:after{
      //  font-family: 'iconfont';
      //  content: '\e68c';
      //  font-size: 12px;
      //  position: absolute;
      //  top: 50%;
      //  right: 30px;
      //  transform: translate(0, -50%);
      //  -webkit-transform: translate(0, -50%);
      //  -moz-transform: translate(0, -50%);
      //  -ms-transform: translate(0, -50%);
      //}
    }

    .checkout-types {
      float: none;

      .paypal-logo img {
        width: 100%;
        height: auto;
      }
    }

    table {
      th{
        text-align: left;
        font-weight: normal;
      }
      td {
        font-size: 14px;

        .price {
          color: $color-black;
        }

        &:first-child {
          text-align: left;
        }
      }

      tfoot {
        background: none;

        td {
          font-size: 16px;

          .price {
            color: $c-price
          }
        }
      }
    }

  }

  @media (max-width: $screen__m) {
    .page-title{
      margin: 0;
    }
    .cart-collaterals .col2-set {
      width: auto;
      float: none;
    }
    .totals {
      width: auto;
      float: none;
    }

  }
}

.cart-table {
  border-right: none;

  th {
    border: none;
    border-bottom: 1px solid $c-grey;
  }

  thead {
    background: none;
  }

  dl.item-options {
    text-align: left;

    dt {
      float: left;
      margin-right: 10px;
      font-style: normal;
    }
  }

  tbody tr {
    &.first {
      td {
        padding-top: 40px;
      }
    }

    td {
      padding: 15px 10px
    }
  }

  td {
    border: none;

    &:first-child {
      width: 10%
    }

    &.product-name {
      width: 30%;
      vertical-align: top;
      height: auto;
      display: table-cell;
      overflow: visible;

    }

    &.product-img {
      min-width: 75px;

      img {
        width: 100%;
        height: auto;
      }
    }

    &.actions a {
      color: $c-desc-title;
      font-size: 12px;
    }

    &.qty {
      .to-cart-container {
        margin: 0 auto;
      }
    }
  }

  tr.even {
    background: none;
  }

  h2.product-name {
    //margin: 0;

    a {
      font-size: 14px;
      font-weight: normal;
      text-align: left;
    }
  }

  tfoot {
    td{
      padding: 0;
    }
    .toolbar-actions {
      text-align: right;
      border: 1px solid $c-grey;
      border-right: none;
      border-left: none;
      padding: 20px 0;

      button:first-child {
        margin-right: 30px
      }
    }
  }

  .unitPrice .price {
    color: $c-desc-title;
  }

  .subtotal .price {
    color: $color-black;
  }

  @media (max-width: $screen__m) {
    colgroup{
      display: none;
    }
    thead {
      display: none;
    }
    tbody {
      tr.first td {
        padding-top: 0;
      }

      tr {
        display: block;
        overflow: hidden;
        position: relative;
        padding: 10px 0 20px;
        border-bottom: 1px solid $c-grey;

        &:last-child {
          border-bottom: none;
        }
      }

      h2.product-name {
        height: auto;
        margin: 0;
      }

      td {
        display: block;
        float: left;

        &.qty{
          padding: 0 10px;
          float: none;
          margin-left: 139px;
          .to-cart-container{
            margin: 0;
          }
        }
        &.unitPrice {
          display: none;
        }

        &.product-img {
          width: 120px;
          margin-right: 20px;
        }

        &.subtotal {
          float: right;
          position: absolute;
          right: 0;
          bottom: 10px;
        }

        &.product-name {
          width: 66%;
          margin: 0;
        }

        &.actions {
          position: absolute;
          top: 0;
          right: 0;
        }
      }
    }
  }
  @media (max-width: $screen__s){
    tbody td{
      &.product-img{
        padding: 0;
        margin-right: 10px;
        width: 80px;
      }
      &.qty{
        margin-left: 90px
      }
    }

    tfoot .toolbar-actions{
      display: flex;
      justify-content: space-between;
      button:first-child{
        margin-right: 0;
      }
    }

  }
}


//checkout

.checkout-onepage-index {
  .page-title {
    margin: 50px 0 24px 0;

    h1 {
      font-size: 20px;
      text-transform: uppercase;
      font-weight: bold;
    }
  }

  .col-main {
    width: 876px
  }

  .col-right {
    width: 254px;
    float: right;
  }

  @media (max-width: $screen__sl) {
    .col-main{
      width: 74%;
    }
    .col-right {
      width: 23%
    }
  }

  p.required {
    margin: 10px 0;
  }

  .opc li.section {
    border: 1px solid $c-grey
  }

  .col2-set {
    .col-2 {
      width: 48%
    }

    .col-1 {
      width: 46%;
      &.guestCheckout{
        .buttons-set{
          margin-top: 27px;
          @media(max-width: 1060px){
            margin-top: 0
          }
        }
      }
    }
  }

  .opc {
    //.active {
    .step {
      border-top: none;
    }

    .step-title {
      padding: 15px 0 15px 31px;
      background: $c-grey;
      border-bottom: 1px solid $c-grey;
      display: flex;
      align-items: center;

      h2 {
        font-size: 18px;
        color: $color-black;
        font-weight: normal;
      }
      .number {
        background: $c-step-active;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        color: $c-white;
        font-weight: normal;
        font-size: 18px;
        text-align: center;
        line-height: 24px;
      }
      @media (max-width: $screen__m) {
        padding-left: 15px;
      }
    }

    //}
    .step {
      h3 {
        font-size: 18px;
        text-transform: uppercase;
        font-weight: bold;
        margin: 20px 0;
      }

      h4 {
        font-size: 16px;
        margin-bottom: 20px;
      }

      p {
        margin-bottom: 20px;
      }

      #review-buttons-container p {
        margin-top: 40px;
      }

      .form-list {
        label {
          font-weight: normal;
        }

        .input-box {
          width: auto;
        }

        input.input-text {
          border: 1px solid $c-grey;
          height: 40px;
          line-height: 40px;
          width: 100%;
          padding: 0 5px;
        }
      }
    }

    li.section {
      margin-bottom: 20px;
    }
  }

  .col2-set {
    .buttons-set a {
      margin-top: 36px;
      font-size: 16px;
    }
  }

  .form-list li {
    margin-bottom: 10px;

  }

  .form-list li.control, dl#checkout-payment-method-load dt {
    input {
      opacity: 0;
    }

    label {
      position: relative;
      padding: 10px;
      cursor: pointer;

      &:before {
        font-family: 'iconfont';
        content: '\e609';
        float: left;
        position: absolute;
        left: -20px;
        top: 50%;
        transform: translate(0, -50%);
        -webkit-transform: translate(0, -50%);
        -moz-transform: translate(0, -50%);
        -ms-transform: translate(0, -50%);
        color: $c-grey;
        font-size: 22px;
      }

    }

    &.active {
      label:before {
        color: $c-step-active
      }
    }
  }
  @media (max-width: $screen__m) {
    .col-main{
      width: auto;
      float: none;
    }
    .col-right {
      width: auto;
      float: none;
    }
    .col2-set{
      display: flex;
      flex-wrap: wrap;
      .col-1{
        order: 2;
      }
      .col-1, .col-2{
        width: auto;
        float: none;
        width: 100%;
      }
    }
    #checkout-step-login{
      .col-2{
        margin-bottom: 20px;
      }
      a{
        margin-top: 10px;
        text-decoration: underline;
      }

    }
    .btn-primary{
      float: none;
      width: 100%
    }
    .opc .active .step{
      padding: 20px;
    }
    #checkout-shipping-method-load{
      width: auto;
    }
    .checkout-step-payment{
      .sp-methods .form-list{
        padding-left: 0;
      }
    }
    #checkout-review-table-wrapper .data-table tfoot tr{
      max-width: 500px;
      width: 168%;
    }
    .order-review .product-name{
      line-height: 20px;
    }
  }
  @media (max-width: $screen__xs) {
      #checkout-review-table-wrapper .data-table tfoot tr{
        width: 150%
      }
  }
}

#checkout-progress-wrapper {
  .block-progress .block-title {
    margin-bottom: 10px;
    border-bottom: 1px solid $c-grey;
    padding: 0;
    strong {
      font-size: 14px;
      font-weight: bold;
      text-transform: uppercase;
      span {
        line-height: 24px;
        padding: 0
      }
    }
  }
}

.sidebar .block-progress {
  dl {
    border: none;
  }

  dt {
    margin-bottom: 10px;
    background: $c-desc-title;
    padding: 10px;
    font-size: 14px;
    font-weight: normal;
    color: $c-white;
  }

  dt {
    &.complete {
      background: none;
      font-size: 14px;
      border: none;
      color: $color-black;
      padding: 0;
      font-family: $baseFont;
      a {
        float: right;
        color: $c-desc-title;
        text-decoration: underline;
      }
    }
  }

  dd.complete {
    margin-bottom: 30px;
    border: none;
    background: $c-grey;
    color: $color-black;
    font-size: 14px;
    line-height: 20px;
    padding: 10px;

    span.price {
      display: block;
      margin-top: 10px
    }
  }
}

#checkout-payment-method-load > ul.form-list li.active {
  label:before {
    color: $c-step-active
  }
}

#checkoutSteps .btn-primary {
  background: $c-font;
  padding: 15px 40px;
  border-radius: 0;

  span {
    font-size: 18px;
  }
}

.guestCheckout {

  h4 {
    font-weight: bold;
    margin-top: 50px;
  }
}

.checkout-onepage-index .opc {
  border-bottom: none;

  .step {
    .guestCheckout {
      p {
        opacity: .5;
        margin-bottom: 10px;
      }

      ul.ul {
        opacity: .5;

        li {
          margin-bottom: 10px;
        }
      }
    }
  }
}

#checkout-step-billing {
  .customer-name {
    display: flex;
    justify-content: space-between;

    .field {
      width: 48%
    }
  }
}

#co-billing-form, #co-shipping-form {

  label {
    font-size: 14px;
    color: $color-black
  }

  input[type='text'], select {
    border: 1px solid $c-grey;
    border-radius: 0;
    height: 40px;
    line-height: 40px;
    padding: 0 5px;
  }

  select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    width: 100%;
    background: url("../images/downArrow.png") no-repeat 97% center/ 15px;
  }
  select::-ms-expand {
    display: none;
  }

  .form-list ul li {
    margin-bottom: 20px;

    &:after {
      display: none;
    }

    &.fields, .customer-name {
      display: flex;
      justify-content: space-between;

      .field {
        width: 48%
      }
    }

    &.customerName {
      display: block;
    }

  }
}

#co-shipping-form {
  .form-list ul li.company-email {
    display: block;
  }
}

#checkout-shipping-method-load {
  width: 300px;
  label {
    font-weight: normal;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  input {
    opacity: 0;
    position: absolute;
  }
}

#opc-payment {
  .sp-methods {
    dt {
      padding-bottom: 0;
      margin: 8px 40px 9px 0;

      &:nth-child(2) {
        margin-top: 24px;
      }
    }

    dd {
      margin-left: 32px;
      @media (max-width: $screen__m) {

      }
      .form-list {
        display: block !important;
      }
    }
    .form-list{
      padding-left: 0;
    }
  }


  .payment-list li {
    &.bankpayment.active {
      dt {
        float: none;
      }
      ul#payment_form_bankpayment{
        display: block !important;
      }
      dd {
        float: none;
        width: auto;
        margin: 20px;

        label {
          padding: 10px 0;

          &:before {
            display: none;
          }
        }
      }
    }
  }

  .paypal-logo {
    text-align: right;
    margin-top: 50px;
    @media (max-width:$screen__m) {
      margin-top: 20px;
    }
  }
}

#checkout-review-table-wrapper .data-table {
  thead {
    background: none;
  }

  td, th {
    background: none;
    border: none;
    text-align: center;

    &:first-child {
      text-align: left;
    }
  }

  td span.price {
    color: $color-black
  }
  h5.product-name{
    display: block;
  }

  th {
    //&.product-name {
    //  width: 40%
    //}

    &.price {
      color: $color-black
    }
  }

  tbody {
    border-bottom: 1px solid $c-grey;
  }

  tr.even {
    background: none;
  }

  tfoot {
    tr {
      //display: flex;
      //justify-content: space-between;
      //width: 500px;

      &.first {
        margin-top: 30px;
      }

      td {
        &:first-child {
          text-align: left;
        }

        &:last-child {
          text-align: right;
        }
      }
    }
  }

  //.a-right{
  //  text-align: right;
  //}
  @media(max-width: $screen__sm){
    thead{
      display: none;
    }
   tbody{
     tr{
       display: block;
       overflow: hidden;
       margin-bottom: 20px;
       border-bottom: 1px solid $c-grey;
       padding-bottom: 20px;
       width:136%;
       &:last-child{
         border-bottom: none;
         margin-bottom: 0;
       }
     }
     td{
       display: block;
       float: left;
       margin-right: 20px;
       &:first-child{
         float: none;
         width: 100%;
         margin-right: 0;
         h5.product-name{
           margin: 10px 0;
         }
       }
     }
   }
  }
  @media(max-width: $screen__xs){
    tbody tr{
      width: 115%
    }
  }
  @media(max-width: $screen__s){
    tbody tr{
      width: 119%
    }
  }
}


//empty cart

.checkout-cart-empty {
  .page-title {
    margin: 50px 0;
    text-align: center;
    border-bottom: none;
  }
  .col-main{
    min-height: 200px;
  }

  .cart-empty {
    margin-bottom: 40px;
    p {
      text-align: center;
    }
  }


}

// log out
.customer-account-logoutsuccess {
  .page-title {
    margin-top: 50px;
  }
  .main{
    min-height: 200px;
  }
}

// login page
.customer-account-login {
  .main {
    margin: 90px auto;
  }

  input {
    border: 1px solid $c-grey;
    border-radius: 0;
    height: 36px;
    line-height: 36px;
  }

  .content h2 {
    font-size: 16px;
    margin: 20px 0 10px;
  }

  .form-list {
    margin-top: 20px;

    label {
      font-weight: normal;
    }

    .input-box {
      width: 80%;
    }
  }

@media (max-width: $screen__sl) {
  .main{
    width: auto;
    margin: 90px 30px
  }
}
  @media (max-width: $screen__xm) {
    .main{
      margin: 50px 30px
    }
  }
  @media (max-width: $screen__m) {
    .col2-set{
      .col-2, .col-1{
        float: none;
        width: auto;

      }
      .col-1{
        margin-bottom: 60px;
      }
    }
    .form-list .input-box{
      width: auto;
    }
  }
}

.account-login {
  .buttons-set {
    padding-left: 0;
    display: flex;
    justify-content: space-between;
    align-items: end;
    width: 80%;
    margin: 20px 0;
  }

  .registered-users .buttons-set a {
    padding-right: 0;
  }
  @media (max-width: $screen__m) {
    .buttons-set{
      width: auto;
    }
  }
}

// register page
.customer-account-create {
  .main {
    margin: 90px auto;
  }

  .fieldset {
    padding: 0;
    border: none;

    .legend {
      float: none;
      background: none;
      margin: 0 0 10px;
      padding: 0;
      font-size: 16px;
    }
  }

  #form-validate {
    display: block;

    label {
      font-size: 14px;
      color: $color-black
    }
  }
  @media (max-width: $screen__sl) {
    .main{
      margin: 50px 30px;
    }
  }
  @media (max-width: $screen__m) {
    .form-list {
      .field{
        float: none;
        width: auto;
      }
      .input-box{
        width: auto;
      }
    }
  }
}

.account-create #form-validate .buttons-set {
  display: flex;
  justify-content: flex-start;
  float: none;
  align-items: end;

  .back-link {
    order: 1;
  }

  button {
    order: 2;
  }
}

.accountPage, .sales-billing-agreement-index {
  .main {
    margin-top: 40px;
  }

  .col-main {
    width: 801px
  }
}


//account
.accountPage, .sales-billing-agreement-index {
  .customer-name, .fields{
    display: flex;
    width: 100%;
    justify-content: space-between;
    &:after{
      display: none;
    }
  }
  .form-list {
    input.input-text, .input-box, select{
      width: 100%;
    }
    .field{
      width: 48%
    }
  }
  .btn-remove{
    text-indent: 0;
  }
  select {
    border: 1px solid $c-grey;
    border-radius: 0;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    background: url("../images/downArrow.png") no-repeat 97% center / 12px;
    height: 36px;
    line-height: 36px;
  }
  select::-ms-expand {
    display: none;
  }
  .form-list li.wide{
    .input-box{
      width: auto
    }
    input.input-text{
      width: 100%;
    }
  }

  .sidebar {
    width: 240px;
  }

  h1 {
    font-size: 18px;
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .page-title {
    border-bottom: none;
    padding-bottom: 0;
  }

  .box-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $c-grey;
    padding-bottom: 10px;
    margin-bottom: 10px;

    &:after {
      display: none !important;
    }

    h3 {
      font-size: 18px;
      font-weight: bold;
    }

    a {
      font-size: 12px;
      color: $c-desc-title;
      text-decoration: underline;
    }
  }

  .buttons-set button.button {
    background: $c-coupon-btn;
    padding: 10px;
    color: $c-white;

    span {
      background: none;
      color: $c-white;
      font-size: 14px;
    }
  }
  @media (max-width: $screen__sl) {
    .col-main{
      width: 61%
    }
    .sidebar{
      width: 31%
    }
  }
  @media (max-width: $screen__m) {
    .col-main, .sidebar{
      width: 100%;
      float: none;
    }
    .main{
      display: flex;
      flex-wrap: wrap;
      margin-top: 0;
    }
    .col-main{
      order: 2;
    }
    .sidebar{
      margin-bottom: 20px;
    }
  }
}

.box-account {
  margin-top: 100px;

  .col2-set {
    margin-top: 60px;

    .col-2, .col-1 {
      width: 42%;
    }
  }

  .box-content {
    a {
      color: $c-desc-title;
      text-decoration: underline;
      margin-top: 10px;
      display: block;
    }

    p {
      color: $c-66
    }

    h4 {
      font-size: 14px;
      color: $c-66;
      margin-bottom: 10px;
    }
  }
  @media (max-width: $screen__xs) {
    margin-top: 40px;
    .col2-set{
      margin-top: 0;
      .col-1, .col-2{
        float: none;
        width: auto;
        margin-bottom: 30px;
      }
    }
  }
}

.welcome-msg p {
  color: $c-99;

  &.hello {
    color: $c-66;
    margin-bottom: 10px;
  }
}

.sidebar .block {
  border: none;
  background: $c-grey;

  .block-title {
    background: none;
    border-bottom: none;
  }

  .block-content {
    padding: 15px 20px;
  }

  &.block-account li {
    margin: 20px 0;
    position: relative;
    padding-left: 35px;

    &:before {
      font-family: 'iconfont';
      float: left;
      display: block;
      position: absolute;
      left: 0;
      font-size: 22px;
      top: 50%;
      transform: translate(0, -50%);
      -webkit-transform: translate(0, -50%);
      -moz-transform: translate(0, -50%);
      -ms-transform: translate(0, -50%);
    }

    &.navNum2:before {
      content: '\e607'
    }

    &.navNum3:before {
      content: '\e604'
    }

    &.navNum4:before {
      content: '\e668'
    }

    &.navNum5:before {
      content: '\e616';
      font-size: 28px;
      left: -3px
    }

    &.navNum6:before {
      content: '\e614'
    }

    &.navNum7:before {
      content: '\e602'
    }
  }

  &.block-compare {
    background: none;

    strong span {
      font-size: 18px;
    }

    li {
      padding: 0;
      display: flex;
      margin-bottom: 40px;

      &.even {
        background: none;
      }

      &:last-child {
        margin-bottom: 0;
      }

      .product-name {
        padding: 0;
        margin: 0 0 10px 0;

        a {
          font-size: 14px;
          line-height: 20px;
          font-weight: normal;
          text-align: left;
        }

      }

      a.product-image {
        width: 80px;

        img {
          width: 100%;
          height: auto;
        }
      }

      .product-detail {
        width: 70%;
        margin-left: 20px;
      }

      .btn-remove {
        float: right;
        text-indent: 0;
        font-size: 0;
      }
    }

    .block-content {
      padding: 30px;
      background: $c-grey;
    }

    button.button {
      background: none;

      span {
        text-decoration: underline;
        background: none;
        border: none;
        font-size: 16px;
        text-transform: lowercase;
        padding: 0;
      }
    }

    .actions {
      padding: 20px 0 0 0;
    }
  }

}


@media (max-width: $screen__m) {
  .sales-order-view{
    .data-table{
      width: auto;
      margin-right: 10px;
      tbody.even{
        background: none;
      }
    }

    .order-details{
      .data-table {
        tr{
          border-bottom: 1px solid $c-grey;
          padding: 20px 0;
          &:last-child{
            border-bottom: none;
          }
        }
        td{
          width: auto;
          float: none;
          display: flex;
          flex-wrap: wrap;
          h3.product-name{
            margin: 0;
            height: auto;
            width: 80%;
          }
          dl{
            display: block;
            width: 100%;
            columns: 1;
            -webkit-columns: 1;
            -ms-columns: 1;
            -moz-columns: 1;
            dt{
              float: left;
              margin-right: 10px;
            }
          }
        }
        tfoot {
          tr{
            padding: 5px 0;
          }
          td:first-child{
            display: none;
          }
        }
      }
    }
  }
}
@media (max-width: $screen__sm) {
  .sales-order-view .order-details .data-table td h3.product-name{
    width: 74%
  }
}
@media (max-width: $screen__s) {
  .sales-order-view .order-details .data-table td h3.product-name{
    width: 60%
  }
}
.accountPage {
  .fieldset {
    border: none;
    background: none;
    padding: 0;

    .legend {
      float: none;
      margin: 0 0 20px;
      background: none;
      font-size: 16px;
      border-bottom: 1px solid $c-grey;
      padding: 0 0 5px;
    }
  }

  .form-list li {
    margin-bottom: 15px
  }


  .col2-set.addresses-list {
    h2 {
      font-size: 16px;
      margin-bottom: 10px;
    }

    h3 {
      font-size: 14px;
      margin-top: 10px;
      border-bottom: 1px solid $c-grey;
      padding-bottom: 10px;
    }

    .col-1, .col-2 {
      float: none;
      width: auto
    }

    ol li {
      margin-bottom: 40px;
    }

    address {
      margin-bottom: 0;
    }

    a {
      color: $c-desc-title;
      text-decoration: underline;
      display: block;
      margin-top: 10px;
    }
  }

  .order-info {
    border: none;
    border-bottom: 1px solid $c-grey;
  }

  .order-info-box {
    margin: 20px 0 40px 0;

    .box-title h2 {
      font-size: 14px;
    }
  }

  .order-details {
    h2 {
      font-size: 14px;
      margin-bottom: 20px;
    }

    h3.product-name {
      font-size: 14px;
    }
    .price-excl-tax{
      display: inline-block;
    }
  }
  @media (max-width: $screen__m) {
    .col2-set{
      .col-1, .col-2{
        float: none;
        width: auto;
      }
    }
    .data-table{
      thead{
        display: none;
      }
      tr{
        display: block;
        overflow: hidden;
        border-bottom: 1px solid $c-grey;
        padding: 20px 0;
        &.even{
          background: none;
        }
      }
      td{
        display: block;
        float: left;
        width: 50%;
        border: none;
        &:before{
          content: attr(data-th);
          font-size: 14px;
          display: inline-block;
          margin-right: 10px;
          font-weight: bold;
          white-space: nowrap;
        }
        &.actions:before{
          display: none;
        }
      }

    }
  }
  @media (max-width: $screen__s) {
    .data-table{
       td{
        width: auto;
        float: none;
      }
    }
  }
}
.wishlist-index-index{
  .product-name a{
    line-height: 20px;
  }
  .buttons-set{
    margin-top: 20px;
    button{
      margin-right: 20px;
      &.btn-share{
        margin-right: 0;
      }
    }
  }
  .wishlist-list {
    li{
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid $c-grey;
      padding: 20px 0;
      align-items: center;
      &:last-child{
        border-bottom: none;
      }
    }
    input.qty{
      width: 100%;
      max-width: 50px;
      text-align: center;
    }
    .productDetail{
      display: flex;
      align-items: center;
      width: 60%;
      h2{
        width: 40%;
        margin: 0 30px;
      }

    }
    @media (max-width: $screen__m) {

      li{
        flex-wrap: wrap;
      }
      .productDetail{
        width: 100%;
        h2{
          margin: 0 10px;
        }
      }
      div.qty{
        margin-left: 30%
      }
    }
  }
  button{
    &.btn-cart{
      padding: 5px 10px;
      span,  span span{
        font-size: 12px;
        padding: 0;
      }

      &:before{
        font-size: 18px;
        margin-right: 10px;
      }
    }
    &.button{
      span, span span{
        padding: 0;
      }
    }
  }
  @media (max-width: $screen__sm) {
   .buttons-set button{
      float: none;
      width: 100%;
      margin: 0 0 20px 0;
    }
  }
}

// 404
.cms-index-noroute {
  .std {
    display: none;
  }
}

.noRoutepage {
  margin: 120px 0;

  h1 {
    font-size: 500%;
  }

  h3 {
    font-size: 200%;
    margin: 20px 0;
    color: $c-desc-title
  }

  p {
    font-size: 16px;
  }

  a {
    display: inline-block;
    margin-top: 20px;
  }
}
