@mixin btn($h,$bc:$c-green,$fc:#fff) {
  line-height: $h;
  height: $h;
  text-align: center;
  border: none;
  border-radius: $h/2;
  background: $bc;
  color: $fc;
  padding: 0;
  font-weight: normal;
  font-size: 12px;
  display: block;
}

@mixin btn-form($h,$bc:$c-green,$fc:#fff) {
  line-height: $h;
  height: $h;
  text-align: center;
  border: none;
  border-radius: 3px;
  background: $bc;
  color: $fc;
  padding: 0;
  font-weight: normal;
  font-size: 20px;
  display: block;
  width: 100%;
}

@mixin iconfont($code:'') {
  font-family: 'iconfont';
  content: $code;
  font-size: 30px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
}

@mixin Ymiddle {
  top: 50%;
  transform: translateY(-50%);
}

@mixin Xcenter {
  left: 50%;
  transform: translateX(-50%);
}

@mixin Zcenter {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

@mixin Disable {
  pointer-events: none;
  filter: grayscale(100%);
}