/*================================= import start =================================*/
@import "override";
@import "swiper";
//@import "animate";
@import "var";
@import "mixin";
//@import "layout";

@import "public";
//@import "module";
@import "other";
/*---------------------------------  import end  ---------------------------------*/

//homepage
.cms-index-index {
  .main {
    margin: 0 auto;
    width: auto;
  }

  .breadcrumb-container {
    display: none;
  }

  .top_banner {

    //@include Xcenter;
    position: relative;

    .swiper-container {
      width: 100%;
    }

    .swiper-pagination {
      bottom: 20px;
      width: 100%;
      @media (max-width: $screen__xs) {
        bottom: 0
      }

      .swiper-pagination-bullet {
        border: 1px solid white;
        opacity: 1;
        margin: 10px;
        width: 10px;
        height: 10px;

        &-active {
          background: white;
        }
      }
    }

  }

  .view_lists {
    max-width: $w-content;
    margin: auto;
    @media (max-width: $screen__sl) {
      margin: 0 30px;
      .block_img.moreImg a.img_link {
        width: 49%
      }
    }
 
    .view_title {
      text-align: center;
      font-size: 30px;
      margin: 40px;
    }

    .block_img {
      margin-top: 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .product-item {
      position: relative;

      .product-image {
        position: relative;
        background: $c-grey;
        margin: 0;
        width: 100%;
        padding-bottom: 100%;
        display: block;

        img {
          display: block;
          max-width: 100%;
          width: 100%;
          height: auto;
          position: absolute;
          @include Zcenter;
        }
      }

      .product_info {
        position: relative;
        padding: 1px 0;
      }


      .price-box {
        text-align: center;


        .regular-price {
          .price {
            font-size: 16px;
            color: #000000;
            font-weight: normal;
          }
        }
      }
    }

    .swiper-pagination {
      position: static;
      margin-top: 30px;

      .swiper-pagination-bullet {
        border: 1px solid black;
        opacity: 1;
        margin: 10px;
        width: 10px;
        height: 10px;
        background: white;

        &-active {
          background: black;
        }
      }
    }

    .hot_products, .new_products {
      .product-item {
        &:after {
          width: 80px;
          height: 40px;
          background: rgba(255, 114, 0, 1);
          border-radius: 0px 20px 20px 0px;
          display: block;
          font-size: 16px;
          color: white;
          text-align: center;
          line-height: 40px;
          position: absolute;
          top: 20px;
          left: 0;
        }
      }
    }

    .new_products {
      .product-item {
        &:after {
          content: "NEW";
        }
      }
    }

    .hot_products {
      .product-item {
        &:after {
          content: "HOT";
          background: red;
        }
      }
    }

    @media (max-width: $screen__xs) {
      margin: 0 20px;
      .block_img {
        margin-top: 20px;

        &.moreImg a.img_link {
          width: 48%
        }
      }
      .view_title {
        margin: 30px;
        font-size: 24px;
      }

      .hot_products, .new_products {
        .product-item:after {
          height: 30px;
          line-height: 30px;
          width: 60px;
          font-size: 14px;
        }
      }
    }
    @media (max-width: $screen__s) {
      .view_title {
        font-size: 18px;
      }
      .product-item .product_name a {
        font-size: 12px;
      }
    }
  }

}

//compare list


.catalog-product-compare-index {
  .page-title {
    border-color: $c-grey;
    padding-bottom: 10px;
    margin-bottom: 40px;
    display: flex;
    justify-content: space-between;

    &:after {
      display: none;
    }

    h1 {
      font-size: 20px;
      font-weight: bold;
      text-transform: uppercase;
    }

    .buttons-set {

      span {
        font-size: 0;
      }

      button.button {
        background: none;

        &:before {
          font-size: 24px;
        }

        span {
          background: none;
          border: none;
          display: none;
        }
      }
    }
  }

  .data-table {
    th {
      background: $c-grey;
      border: none;
      border-bottom: 1px solid $c-compare-border;
      min-width: 224px;
      text-align: center;
      font-size: 16px;
      font-weight: normal;
    }

    th, td {
      padding: 30px 20px;
    }
  }

  .product-image img {
    width: 100%;
    height: auto;
  }

  .price-box {
    text-align: center;

    .price {
      font-size: 20px;
    }
  }

  .actions {
    text-align: center;

    button.btn-cart {
      width: 200px;
      position: relative;
      padding: 5px 0;
      &:before {
        margin-right: 15px;
        display: inline-block;
      }

      span, span span {
        padding: 0;
      }
    }
  }

  .link-wishlist {
    border: 1px solid $c-desc-title;
    padding: 15px 0;
    text-align: center;
    display: inline-block;
    font-size: 16px;
    color: $c-desc-title;
    width: 200px;

    &:before {
      font-family: 'iconfont';
      content: '\e601';
      font-size: 18px;
      display: inline-block;
      margin-right: 15px;
    }
  }

  .add-to-links li {
    text-align: center;
    margin-top: 10px;
  }
}

// all categories

.featured_category {
  border: none;

  .head {
    font-size: 22px;
    height: auto;
    padding: 0 0 15px 0;
    background: none;

    &, span {
      color: $c-font;
    }
  }

  ul {
    padding-left: 0;
    margin: 30px;

    li {
      margin: 0

    }
  }

  li h3 {
    margin-bottom: 10px;

    a {
      font-size: 18px;
      color: $c-font;
    }
  }

  .dl_dot dt {
    background: none;
    margin: 10px 0;
    padding-left: 0;

    a {
      font-size: 16px;

    }
  }

  .categories-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  @media (max-width: $screen__sm) {
  .head {
    font-size: 18px;
  }
    ul{
      margin: 30px 10px;
    }
  li h3 a {
    font-size: 16px;
  }

  .dl_dot dt a {
    font-size: 14px;
  }
}
}


//cms page
.cms-page-view{
  .breadcrumb-container{
    display: block;
  }
.margin_t{
  margin-top: 20px;
}
}

// track

.sales-guest-form{
  em{
    font-style: normal;
    color: $c-red
  }
  ul.form-alt {
    margin-top: 20px;
    li{
      margin-bottom: 20px;
      display: block;
      &.note{
        margin-bottom: 3px;
      }
      .input-box:first-child{
        margin-bottom: 3px;
      }
    }
    label{
      font-weight: normal;
    }
  }
  button.button{
    background: $c-font;
    border: none;
    padding: 10px 30px;
    span, span span{
      background: none;
      color: $c-white;
    }
  }
  input, select{
    height:40px;
    line-height: 40px;
    width: 300px;
    padding: 0 5px;
  }
  @media (max-width: $screen__m) {
    ul.form-alt li{
      display: block;
    }
    input, select{
      width: 100%;
    }
  }
}

.sales-guest-view{
  .data-table{
    th{
      width: 22%;
      text-align: center;
    }
    td{
      text-align: center;
      vertical-align: center;
    }
    tfoot td{
      text-align: right;
    }
  }
  h1{
    text-align: center;
    font-size: 24px;
    margin-bottom: 20px;
  }
  h2{
    font-size: 18px;
    margin-bottom: 10px;
  }
  .order-details{
    margin-top: 30px;
  }
  .product-name{
    font-size: 14px;
    line-height: 22px;
  }
  .order-info{
    border: none;
    padding: 0;
    margin-top: 25px
  }
  @media (max-width: $screen__m) {
    .col2-set{
      .col-2, .col-1{
        float: none;
        width: auto;
      }
    }
  }
}

//contact us
.contacts-index-index{
  .fieldset{
    border: none;
    background: none;
    padding: 0;
    .legend{
      margin: 0 0 10px 0;
      background: none;
      float: none;
      padding: 0;
      font-weight: normal;
      font-size: 16px;
    }
  }
  .form-list li{
    margin-bottom: 20px;
  }
  input{
    height: 40px;
    line-height: 40px;
  }
  label{
    font-weight: normal;
  }
  @media (max-width: $screen__m) {

    .form-list{
      &.wide{
        textarea{
          width: 100%
        }
        .input-box{
          width: auto;
        }
      }
      .field{
        float: none;
        width:auto
      }
      .input-box{
        width: auto;
      }
      input.input-text{
        width: 100%;
      }
    }
  }

}