@font-face {
  font-family: "Roboto-Regular";
  src: url("../fonts/roboto-regular/Roboto-Regular.woff2") format("woff2"),
  url("../fonts/roboto-regular/Roboto-Regular.woff") format("woff"),
  url("../fonts/roboto-regular/Roboto-Regular.ttf") format("truetype"),
  url("../fonts/roboto-regular/Roboto-Regular.eot") format("embedded-opentype"),
  url("../fonts/roboto-regular/Roboto-Regular.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

$baseFont: 'Roboto-Regular';
ol, ol li, ul, ul li, html, body, div, p {
  margin: 0;
  padding: 0;
  font-family: $baseFont;
  font-size: 14px;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
}

a {
  color: black;

  &:hover {
    text-decoration: none;
  }
}

img {
  max-width: 100%;
}


.breadcrumb-container {
  display: none;
}

#form-validate {
  label {
    font-size: 14px;
    color: $color-black;
  }

  input[type='text'], input[type='password'] {
    border: 1px solid $c-grey;
    border-radius: 0;
    height: 36px;
    line-height: 36px;
    padding: 0 5px;
  }
}

.btn-primary {
  background: $c-font;
  padding: 20px 40px;
  font-size: 14px;
  border: none;
  border-radius: 0;
  padding: 20px;
  border: 1px solid transparent;

  &:hover, &:visited, &:active {
    background: $c-font;
    border-color: $c-font;
  }
}

@media (max-width: $screen__m) {
  .mobileHidden {
    display: none !important;
  }

}

@media (min-width: ($screen__m + 1)) {
  .pcHidden {
    display: none !important;
  }
}

// pub
.btn-remove {
  background: none;
  line-height: normal;

  &:before {
    font-family: 'iconfont';
    content: '\e62a';
    font-size: 12px;
    color: $c-font;
    display: block;
  }

}

button.btn-cart {
  background: $c-to-cart;
  padding: 5px 20px;

  &:before {
    font-family: 'iconfont';
    content: '\e6ca';
    font-size: 24px;
    color: $c-white;
  }

  span {
    padding-left: 10px;
  }

  span, span span {
    background: none;
    font-size: 16px;
    font-weight: normal;
  }
}

.price-box {
  .minimal-price-link {
    height: 32px;
    width: 100%;
    position: absolute;
    background: #EF1A2D;
    color: $c-white;
    bottom: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .label-info {
      background: none;
      font-size: 16px;
      font-weight: normal;
      color: $c-white
    }

    .price {
      color: white;
      font-size: 16px;
    }
  }

}

.product_name, .product-name {
  height: 40px;
  margin: 12px 0;
  display: flex;
  line-height: 20px;
  position: relative;
  overflow: hidden;

  a {
    font-size: 14px;
    font-weight: normal;
    text-align: center;
    line-height: 20px;
    margin: 0;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    position: relative;
    //top: 50%;
    //transform: translateY(-50%);
  }
}

.regular-price .price {
  font-weight: normal;
}

p.required {
  font-size: 14px;
}

.col-main.full-row {
  width: auto !important;
  float: none;
}

.messages li li {
  margin: 0;
}

.btn-lg, .btn-group-lg > .btn {
  border-radius: 0;
}

.error-msg, .success-msg, .note-msg, .notice-msg {
  border: none !important;
  background: $c-grey !important;
  font-weight: normal !important;
  position: relative;
  padding-left: 50px !important;

  &:before {
    font-family: 'iconfont';
    font-size: 20px;
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translate(0, -50%);
    -webkit-transform: translate(0, -50%);
    -moz-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
  }

}

.note-msg, .notice-msg {
  &:before {
    content: '\e60a';
  }
}

.error-msg {
  color: $c-red;

  &:before {
    content: '\e60a';
  }
}

.success-msg {
  color: $c-green;

  &:before {
    content: '\e626';
  }
}

.price {
  color: $c-price;
  font-size: 16px;
  font-weight: normal;
}

.toolbar-bottom {
  .pager {
    .limiter,
    .amount,
    .sort-by {
      display: none;
    }
  }

}

.breadcrumb-container {
  background: $c-breadcrumb;
  padding: 10px 0;
  margin-bottom: 40px;
}

.breadcrumbs {
  float: none;
  max-width: $w-content;
  margin: 0 auto;

  li {
    a {
      color: $c-breadcrumb-a;
    }

    span, strong {
      color: $c-breadcrumb-text;
      font-weight: normal;
    }
  }

  @media (max-width: $screen__sl) {
    margin: 0 30px;
  }
}


//header
.header-container {
  padding: 0;

  .topNotice {
    background: $c-desc-title;
    padding: 20px 0;
    color: $c-white;
    text-align: center;
  }

  .NoticeContainer {
    max-width: $w-content;
    margin: 0 auto;
    padding: 0 20px;
    position: relative;


    .text-box{
     width: 100%;
      text-align: center;
      overflow: hidden;
    }
    .close {
      position: absolute;
      display: block;
      right: 0;
      top: 0;
      opacity: 1;
      font-weight: normal;
      color: $c-white;
      font-size: 14px;
    }

    @media(max-width: $screen__sl) {
      //margin: 0 30px;
      //padding: 0;
      p {
        -webkit-animation: marquee 15s linear infinite;
        animation: marquee 15s linear infinite;
        white-space: nowrap;
        width: 1130px;
        &:hover{
          animation: none;
          -webkit-animation: none;
        }
      }
      .close{
       top: -10px;
        right: 10px;
      }

    }
    @keyframes marquee {
      0% {
        transform: translateX(200px);
      }
      100% {
        transform: translateX(-1130px);
      }
    }



  }

  .header {
    height: auto;

  }

  .header_top {
    max-width: 1170px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    height: 60px;
    align-items: center;

    .top_links {
      ul {
        display: flex;
        align-items: center;

        > li {
          height: 36px;
          border-right: 1px solid #ededed;


          a {
            padding: 0 24px;
            line-height: 36px;
            text-transform: capitalize;
          }

          &:last-child {
            border: none;
            padding-left: 19px;

            a {
              padding-left: 30px;
              position: relative;
            }

            span {
              margin-right: 5px;
              font-size: 24px;
              position: absolute;

              left: 0;
              top: 50%;
              transform: translateY(-50%);
              -webkit-transform: translateY(-50%);
              -moz-transform: translateY(-50%);
              -ms-transform: translateY(-50%);
            }
          }

        }
      }

      .currency_switch {
        position: relative;

        .tab_right1 {
          padding: 5px 10px;
          background: white;
          font-size: 12px;
          display: none;
          position: absolute;
          top: 100%;
          z-index: 3;
          box-shadow: 0 0 3px 1px rgba(0, 0, 0, .1);

          p {
            margin: 0;
          }

          a {
            line-height: 24px;
          }

          span {
            white-space: nowrap;
          }
        }

        &:hover {
          .tab_right1 {
            display: block;
          }
        }
      }

      .currency_select {
        line-height: 36px;
        text-align: center;
        cursor: pointer;

        &:after {
          margin-left: 3px;
          @include iconfont("\e68c");
          font-size: 12px;
        }

      }
    }
  }

  .hot_key {
    a {
      display: inline-block;
      color: #aaaaaa;
      font-size: 14px;
      padding: 10px;
      position: relative;

      &:hover {
        color: red;
      }

      &:after {
        content: "/";
        color: #cccccc;
        position: absolute;
        right: -3px;
      }

      &:last-child {
        &:after {
          display: none;
        }
      }
    }

    .swiper-wrapper {
      display: flex;
    }

    .swiper-slide {
      width: auto !important;
      margin-right: 20px;
    }
  }

  ul.cart-mini li {
    display: flex;
    float: none;
    @media (max-width: $screen__m) {
      &.cart-wdgt {
        display: none;
      }
    }
  }

  .cart-mini-expand {
    border: 2px solid $c-grey;

    border-radius: 0;
    width: 380px;
    box-shadow: none;
    top: 33px;
    right: 0;
    left: auto;
    .miniCart-box{
      padding: 20px;
      max-height: 400px;
      overflow-y: scroll;
      border-bottom: 1px solid $c-grey;
    }
    .miniCart-toolbar{
      padding: 20px;
    }
    .error {
      color: $color-black;
      font-weight: normal;
    }

    .toCart {
      a {
        border: 1px solid $c-grey;
        text-align: center;
        display: block;
        width: 100%;
        padding: 10px 0;
        margin-top: 20px;
        text-transform: uppercase;

        &:hover {
          color: $color-black
        }
      }
    }

    .truncated {
      margin-left: 10px;

      .truncated_full_value {
        dl {
          top: 20px;
          left: 0;
          background: $c-white;
          border: 1px solid $c-grey;
          display: none;
          padding: 10px;
        }

        dt {
          float: left;
          margin-right: 10px;
          font-style: normal;
        }

        dd {
          padding-top: 0;
        }
      }

      .show dl {
        display: block;
        top: 20px;
        left: 0;
      }
    }

    .itemPrice {
      text-align: right;
      margin-bottom: 20px;
    }

    .product-details {
      width: 78%;

      .removeItem {
        float: right;
        font-size: 10px;
      }
    }

    ol li {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid $c-grey;
      margin-bottom: 20px;
    }

    .product-image img {
      width: 70px;
      height: 70px;
    }

    .product-name {
      height: 35px;
      width: auto;
      letter-spacing: normal;
      text-overflow: ellipsis;
      display: -webkit-box;
      line-clamp: 2;
      -moz-cline-clamp: 2;
      -ms-cline-clomp: 2;
      -webkit-line-clamp: 2;
      overflow: hidden;
      -moz-box-orient: vertical;
      -webkit-box-orient: vertical;
      -ms-box-orient: vertical;
      margin: 0 20px 20px 10px;

      a {
        font-weight: normal;
        text-align: left;
      }

    }

    .subtotal {
      border: none;
      font-size: 14px;
      font-weight: normal;

      strong {
        margin-left: 10px;
      }
    }

    .yellow-button-bot {
      background: $c-to-cart;
      color: $c-white;
      border: none;
      font-weight: normal;
      padding: 10px 20px;
      width: auto;
      height: auto;
      float: none;
      margin-top: 20px;
      text-transform: uppercase;
    }
  }

  .header_bottom {
    border-top: 1px solid #ededed;
  }

  #btnSearch {
    background: $c-step-active url("../images/searchIcon.png") no-repeat center / 30px;
    border: none;
    height: 44px;
    width: 120px;

  }

  .header_content {
    display: flex;
    max-width: 1170px;
    margin: auto;
    align-items: center;
    justify-content: space-between;
    height: 144px;

    .heaerLogo {
      margin: 0;
    }

    #txtKeyword {
      margin: 0;
      height: 44px;
      width: 100%;
    }

    .search {
      margin: 0 51px;
      flex: 0 1 644px;

      .searchbox {
        width: auto;
        padding-left: 10px;
      }

      .input-group {
        margin: 0;
        display: flex;


      }


    }
  }

  .navbar-inverse {
    margin: 0;
    border-radius: 0;

    #nav {
      max-width: 1170px;
      margin: auto;
      float: none;
      display: flex;
      justify-content: space-around;

      > li {
        padding: 0 2.5%;

        position: relative;


        &.over {
          background: #444444;
        }

        > a {
          font-size: 18px;
          color: #ffffff;
          text-align: center;
          padding: 0;
          line-height: 54px;
        }

        @media (min-width: ($screen__m - 1)) {
          float: none;
        }
      }
      & > .active{
        background: #444;
        & > a {
          &, &:focus, &:hover, &:visited {
            background: none;
          }
        }
      }
    }
  }

  @media (max-width: $screen__sl) {
    .header_top, .header_content {
      margin: 0 30px;
    }
    .hot_key a {
      padding: 10px 5px;
    }
    .header_content {

      .search {
        margin: 0 30px;
      }

    }
    .heaerLogo img {
      width: 100%;
      height: auto;
    }
  }

  @media (max-width: $screen__xm) {
    .top_info {
      font-size: 13px;
    }
    .header_top .top_links ul > li {
      a {
        font-size: 13px;
        padding: 0 10px;
      }

      &:last-child {
        a {
          padding-right: 0;
        }
      }
    }
    .hot_key a {
      font-size: 13px;
    }
    .header_content {
      .search {
        margin: 0 10px;

      }
    }
  }
  @media (max-width: $screen__m) {
    .header_content {
      display: block;
      text-align: right;
      margin: 20px 0;
      height: auto;

      .search {
        float: none;
        margin: 30px 0;
        padding-top: 30px;
        border-top: 1px solid $c-grey;
        clear: both;

        .searchbox, .hot_key {
          padding: 0;
          margin: 0 30px;
        }

        .searchbox {
          margin-bottom: 20px;
        }
      }

      .heaerLogo {
        margin-left: 30px;
      }

      .top_links, #nav-cart, .nav-toogle {
        display: inline-block;
        float: none;
      }
    }

    .top_links {
      position: relative;

      ul {
        display: none;
        position: absolute;
        border: 1px solid $c-grey;
        background: $c-white;
        left: 0;
        transform: translateX(-50%);
        -webkit-transform: translateX(-50%);
        -moz-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        width: 180px;
        text-align: left;
        z-index: 10;

        li {
          border-bottom: 1px solid $c-grey;
          padding: 10px;

          &:last-child {
            border-bottom: none;
          }

          a {
            font-size: 14px;
          }
        }
      }
    }
    .top_links, .nav-toogle {
      margin-left: 20px;

      span.iconfont {
        font-size: 44px;
      }
    }

    .nav-toogle {
      margin-right: 30px;

      span {
        display: block;

        &.closeUp {
          font-size: 38px;

          &:before {
            content: '\e62a';
            width: 44px;
            display: block;
            text-align: left;
          }
        }
      }
    }

    .hot_key {
      a {
        background: $c-grey;
        padding: 10px 30px;
        color: $c-coupon-btn;
        text-align: center;
        font-size: 24px;

        &:after {
          display: none;
        }
      }
    }

    #btnSearch {
      background: none;
      position: absolute;
      left: 10px;
      top: 0;
      z-index: 2;
      padding: 0;
      width: 44px;
      height: 64px;
      background: url("../images/greySearchIcon.png") no-repeat center / 30px;

    }
    .header_content {
      #txtKeyword {
        padding-left: 70px;
        background: $c-grey;
        border: none;
        border-radius: 0;
        box-shadow: none;
        height: 64px;
      }
    }
    .navbar-inverse {
      background: none;
      border: none;
      margin: 0 30px;
      display: none;
      padding-bottom: 40px;

      #nav {
        display: block;

        & > li {
          width: auto;
          border: none;
          border-bottom: 1px solid $c-grey;
          position: relative;

          & > a {
            color: $color-black;
            text-align: left;
            text-transform: uppercase;
            font-weight: 400;
            background: none;
            display: inline;
            font-size: 26px;
            line-height: 60px;

            span {
              display: inline;
            }
          }

          &.over {
            background: none;
          }

          h5 {
            display: none;
          }

          &:first-child {
            border-left: none;
            //display: none;
          }

          &.parent {
            &:after {
              font-family: 'iconfont';
              content: '\e603';
              top: 18px;
              right: 0;
              font-size: 16px;
              color: #d9d9d9;
              display: block;
              position: absolute;

            }

            .nav-wrapper {
              position: static;
              padding: 0;
              display: none;
            }

            &.closeUp {
              &:after {
                content: '\e605'
              }
            }
          }
        }

        & > .active > a {
          &, &:focus, &:hover, &:visited {
            background: none;
          }
        }
      }
    }


  }
  @media (max-width: $screen__sm) {
    .header_content .search {
      .searchbox, .hot_key {
        margin: 0 20px;
      }

      .searchbox {
        margin-bottom: 20px;
      }
    }
  }
  @media (max-width: $screen__xs) {
    .header_content {
      .search {
        margin: 20px 0;
        padding-top: 20px;
      }

      #txtKeyword {
        height: 50px;
      }
    }
    #btnSearch {
      height: 50px;
    }

    .hot_key a {
      font-size: 16px;
    }
    .top_links, .nav-toogle {
      margin-left: 10px;

      span.iconfont {
        font-size: 30px;
      }
    }
    .nav-toogle span.closeUp {
      font-size: 24px;

      &:before {
        width: 30px
      }
    }
    #nav-cart {
      .shopping_cart:before {
        font-size: 34px;
      }

      .cart_qty {
        left: 18px
      }
    }
    .navbar-inverse {
      margin: 0 20px;

      #nav > li > a {
        font-size: 18px;
        line-height: 56px;
      }
    }


  }
  @media (max-width: $screen__s) {
    .header_content .heaerLogo {
      width: 150px
    }
    .hot_key a {
      font-size: 12px;
    }
    .top_links, .nav-toogle {
      span.iconfont {
        font-size: 24px;
      }
    }
    .nav-toogle span.closeUp {
      font-size: 20px;

      &:before {
        width: 24px;
      }
    }
    #nav-cart .shopping_cart:before {
      font-size: 26px;
    }
    .navbar-inverse {
      #nav > li > a {
        font-size: 16px;
        line-height: 46px;
      }
    }
  }
}

@media (max-width: $screen__m) {
  .cms-index-index .header-container .navbar-inverse {
    display: block;
  }
}

//nav
#nav {
  > li {
    position: static;

    ul, div {
      &.shown-sub {
        top: 55px;
      }
    }
  }

  ul.level0 {
    &.shown-sub {
      width: 100%;
      left: 0;
      top: 54px;
      border: none;
      border-radius: 0;
      padding: 40px;
    }
  }

  ul, div {
    border: none;
    position: static;
    box-shadow: none;
    padding: 0;

  }

  div.nav-wrapper {
    position: absolute;
    width: 100%;
    padding: 30px 0;
    border-radius: 0;


    ul.level0 {
      margin-right: 30px;
      float: left;
    }

    & > .sub-container {
      max-width: $w-content;
      margin: 0 auto;
      border-radius: 0;
    }

    div.category_menu_desc {
      position: static;
      float: right;
      display: none;
      width: auto;
    }
  }

  div.category_menu_desc {
    position: absolute;
    width: 100%;
  }

  @media (max-width: $screen__m) {

    li {
      ul, div {
        &.shown-sub {
          border-radius: 0;
          padding: 0;
          display: block;
        }
      }

      ul span {
        padding: 20px 0;
        border-bottom: 1px solid $c-grey;
        color: $c-desc-title;
        margin: 0;
        font-size: 22px;
      }

      .category_menu_desc {
        display: none;
      }

    }
    div.nav-wrapper {
      ul.level0 {
        float: none;
        margin-right: 0;

      }

      div.category_menu_desc {
        float: none;
      }
    }
  }
  @media (max-width: $screen__xs) {
    li ul span {
      font-size: 16px;
      padding: 10px 0;
    }
  }
  @media (max-width: $screen__s) {
    li ul span {
      font-size: 14px;
    }
  }

}

//mini cart
#nav-cart {
  order: 3;
  position: relative;
  padding: 0;
  width: auto;

  .cart-mini {
    display: flex;
    align-items: center;
    margin-top: 0;
    width: auto;
  }

  .shopping_cart {
    &:before {
      @include iconfont("\e6ca");
      font-size: 46px;
    }
  }

  .cart_qty {
    width: 24px;
    height: 24px;
    border-radius: 12px;
    background: red;
    display: block;
    text-align: center;
    line-height: 24px;
    color: white;
    position: absolute;
    top: -10px;
    left: 30px;
  }

  .cart-wdgt {
    > .price {
      font-size: 17px;
      color: black;
      font-weight: normal;
    }
  }
}

.main-container {
  margin: 0 0 30px;
}

.main {
  width: $w-content;
  margin: 40px auto;
}

@media (max-width: $screen__sl) {
  .main {
    width: auto;
    margin: 30px;
  }
}

@media (max-width: $screen__sm) {
  .main {
    margin: 0 20px;
  }
}

//footer
.footer-container {
  background: #F2F2F2;
  padding: 25px 0;

  .footer {
    border: none;
    max-width: $w-content;
    margin: auto;
    //@media (max-width: $screen__sl) {
    //  margin: 0 30px;
    //}
    @media (max-width: $screen__sl) {
      margin: 0 12px;
    }
  }

  .link_box {
    display: flex;
    justify-content: space-between;
@media (max-width: $screen__m) {
  display: none;
}
    .links {
      .block-title {
        font-size: 14px;
        text-transform: uppercase;

        strong {
          line-height: 36px;
        }
      }

      ul {
        margin: 0;
        padding: 0;
        display: block;

        li {
          display: block;

          &.highlight {
            &:after {
              @include iconfont("\e600");
              font-size: 12px;
              color: red;
              transform: scale(0.8);
              margin-left: 5px;
            }
          }

          a {
            color: #666666;
            font-weight: normal;
            line-height: 36px;
          }
        }
      }
    }
  }

  .partners {
    & > p {
      font-size: 16px;
      text-align: left;
      padding-bottom: 15px;
      display: none;
      text-transform: capitalize;
    }

    .partners_box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-top: 1px solid #666666;
      padding-top: 30px;
      margin-top: 37px;
    }
  }

  address {
    margin-top: 60px;
  }

  @media (max-width: $screen__m) {
    padding: 18px;
    .track_order {
      color: white;
      background: #222222;
      line-height: 45px;
      text-align: center;
      font-size: 16px;
      margin: 8px 0 40px;
    }
    .link_box {
      flex-direction: column;

      .links {
        border-bottom: 1px solid gainsboro;

        .block-title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 45px;
          padding-right: 10px;

          &:after {
            @include iconfont("\e68c");
            font-size: 12px;
            transform: rotate(-90deg);
            transition: all 300ms;
          }

          &.closeUp {
            &:after {
              @include iconfont("\e68c");
              font-size: 12px;
              transform: rotate(-180deg);
            }
          }
        }

        ul {
          display: none;
        }
      }
    }
    .partners {
      > p {
        display: none;
      }

      .partners_box {
        border: none;
        flex-wrap: wrap;
        justify-content: flex-start;

        span {
          height: 28px;
          margin: 0 7px 7px 0;

          img {
            max-height: 100%;
          }
        }
      }
    }
    address {
      margin: 16px auto 0;
    }
  }
  @media (max-width: $screen__xs) {
    .footer {
      margin: 0;
    }

  }
}
@media(max-width: $screen__m){
  .cms-index-index {
    .footer-container .link_box{
      display: flex;
    }
    .track_order{
      display: block !important;
    }
  }
  .track_order{
    display: none !important;
  }
  .header-container .hot_key{
    .swiper-wrapper{
      margin-bottom: 12px;
    }
    &.swiper-container-horizontal > .swiper-scrollbar{
      height: 3px;
    }
  }
}
.swiper-pagination {
  .swiper-pagination-bullet {
    &:only-child {
      display: none;
    }
  }
}
